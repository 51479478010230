import { Order, PointEvent, Sale, Tax } from '@/types';

export interface SaleStats {
	subTotal: number;
	tax: number;
	discount: number;
	grandTotal: number;
	totalSaleAmount?: number;
	totalAfterTax?: number;
	profitPerItem?: number;
	profitPercent?: number;
	totalProfit?: number;
	totalCost?: number;
	totalMinusTax?: number;
	totalItems?: number;
	ordersList?: (Order & { key: number })[];
	taxList?: (Tax & { key: number })[];
}

export function getSaleStats(event: PointEvent): SaleStats {
	const totals = {
		totalSaleAmount: event.saleAmount || 0,
		totalTax: 0,
		totalItems: 0,
		totalMinusTax: 0,
		totalDiscounted: 0,
		totalCost: 0,
		profitPerItem: 0,
		profitPercent: 0,
		totalProfit: 0,
	};

	const sale = (event.sale as Sale) || {};

	const ordersList = sale?.orders?.map((order, i) => {
		totals.totalCost += order.totalCost || 0;
		totals.totalDiscounted += order.totalDiscount || 0;
		totals.totalMinusTax += order.totalPrice || 0;
		totals.totalItems += order.quantity || 0;
		return { ...order, key: i };
	});

	const taxList = sale?.taxes?.map((tax, i) => {
		totals.totalTax += tax.amount || 0;
		return { ...tax, key: i };
	});

	const totalAfterTax = totals.totalMinusTax + totals.totalTax;
	const grandTotal = totalAfterTax - totals.totalDiscounted;

	if (totals.totalCost !== 0) {
		totals.totalProfit = totals.totalMinusTax - totals.totalCost;
		totals.profitPerItem = totals.totalProfit / totals.totalItems;
		totals.profitPercent = totals.totalProfit / totals.totalMinusTax;
	}

	return {
		subTotal: totals.totalMinusTax,
		tax: totals.totalTax,
		discount: totals.totalDiscounted,
		grandTotal,
		totalSaleAmount: totals.totalSaleAmount,
		totalAfterTax,
		profitPerItem: totals.profitPerItem,
		profitPercent: totals.profitPercent,
		totalProfit: totals.totalProfit,
		totalCost: totals.totalCost,
		totalMinusTax: totals.totalMinusTax,
		totalItems: totals.totalItems,
		ordersList,
		taxList,
	};
}
