import { OverrideContact } from '@/types';

export const SAMPLE_MERGE_DATA = [
	{
		fn: 'Antoinette',
		ln: 'Williams',
		pl: 'loyalty',
		cn: 'ff0539bf-2f65-4479-a748-5068c0ed33fb',
		sr: '7c4e53c2-3644-4141-be2c-ea6d5347ce73',
		eph: '9999999999',
		em: 'email@gmail.com',
		rg: 'NV',
		fs: 1723532107,
		dm: true,
		lts: 1723532107,
	},
	{
		fn: 'Antoinette',
		ln: 'Williams',
		pl: 'leaflogix',
		cn: '8334e091-14ef-4c2f-a04e-d397f01b3eed',
		sr: '123453243:::Cookies Flamingo',
		hph: '9999999999',
		eph: '9999999999',
		em: 'email@gmail.com',
		rg: 'NV',
		fs: 1723600753,
		cty: 2,
		dm: true,
	},
	{
		fn: 'Antoinette',
		ln: 'Williams',
		pl: 'loyalty',
		cn: '3aced113-d207-4078-8fd1-d0c746b1f3d1',
		sr: '3ee545b3-cefc-4bb5-ab32-1b11b08c8bf4',
		eph: '9999999999',
		em: 'email@gmail.com',
		rg: 'NV',
		fs: 1725284148,
		lts: 1725284148,
	},
];

export const SAMPLE_OVERRIDE_DATA: Record<string, OverrideContact> = {
	'1099:::8334e091-14ef-4c2f-a04e-d397f01b3eed': {
		address: { city: 'Las vegas', country: 'US', zipCode: '89130', state: 'NV', street: '123 abc street', raw: null, lat: 0, long: 0 },
		mobilePhone: '9999999999',
		email: 'email@gmail.com',
		lastName: 'Williams',
		firstName: 'Antoinette',
		favoriteStoreID: 18,
		birthdate: 516448800,
		ts: 1725287933,
	},
	'1099:::ff0539bf-2f65-4479-a748-5068c0ed33fb': {
		address: { city: 'Las vegas', country: 'US', zipCode: '89130', state: 'NV', street: '123 abc street', raw: null, lat: 0, long: 0 },
		mobilePhone: '9999999999',
		email: 'email@gmail.com',
		lastName: 'Williams',
		firstName: 'Antoinette',
		favoriteStoreID: 18,
		birthdate: 516448800,
		ts: 1725287933,
	},
};
