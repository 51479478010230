
// Exports all of the math helper functions
export * from './random';

// Generate a random UUID
// EX: 8b3c4b9a-8f5c-4b9a-8f5c-3c4b9a8f5c4b
export const randomUUID = (alt?: boolean) => {
    if (!alt) { // @ts-ignore
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16))
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
        const random = Math.random() * 16 | 0;
        const value = char === 'x' ? random : (random & 0x3 | 0x8);
        return value.toString(16);
    });
}

export type UnparsedNumberType = (number | string | undefined | any);

// Generate a random number between two numbers
// EX: Generate a random number between 1 and 10
export const randomNumber = (min = 1, max = 10) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Generate a random number between two numbers, excluding a number
// EX: Generate a random number between 1 and 10, excluding 5
export const randomNumberExcluding = (min = 1, max = 10, exclude: number) => {
    let num = randomNumber(min, max);
    while (num === exclude) {
        num = randomNumber(min, max);
    }
    return num;
}

// Generate a random number between two numbers, excluding an array of numbers
// EX: Generate a random number between 1 and 10, excluding 5, 6, and 7
export const randomNumberExcludingArray = (min = 1, max = 10, exclude: number[]) => {
    let num = randomNumber(min, max);
    while (exclude.includes(num)) {
        num = randomNumber(min, max);
    }
    return num;
}

// Select a random item from an array
// EX: Select a random item from an array of strings
export const getRandomItem = <T>(arr: (T[] | readonly T[])): T => {
    return arr[randomNumber(0, arr.length - 1)];
}

// Select x number of random items from an array
// EX: Select 5 random items from an array of strings
export const getRandomItems = <T>(arr: (T[] | readonly T[]), count: number): T[] => {
    const items: T[] = [];
    for (let i = 0; i < count; i++) {
        items.push(getRandomItem(arr));
    }
    return items;
}

// Generate a random number within x% of a number, optionally include a bias to skew the number towards the min or max
// EX: Generate a random number within 10% of 100
export const randomNumberWithinPercent = (num: number, percent: number, bias?: 'min' | 'max') => {
    const min = num - getPercentOf(percent, num);
    const max = num + getPercentOf(percent, num);
    if (bias === 'min') {
        return randomNumber(min, num);
    } else if (bias === 'max') {
        return randomNumber(num, max);
    } else {
        return randomNumber(min, max);
    }
}

// Calculate what percent number is x of another number
// EX: what is x% of y
export const getPercentOf = (percent: number, num: number) => {
    return (percent / 100) * num;
}

// Calculate the percent of a number
// EX: x is what percent of y
export const percent = (num1?: number, num2?: number) => {
    if (!num1 || !num2) return 0;
    return (num1 / num2) * 100;
}

// Calculate the percent difference between two numbers
// EX: What is the percentage difference between num1 and num2
// num1 is the original number, num2 is the new number
export const percentDifference = (num1: number, num2: number) => {
    return ((num1 - num2) / num1) * 100;
}

// Calculate the percent change between two numbers
// EX: What is the percentage increase/decrease from num1 to num2
// num1 is the original number, num2 is the new number
export const percentChange = (num1: number, num2: number) => {
    return ((num2 - num1) / num1) * 100;
}

// Calculate statistics for an array of numbers
// Returns an object with the variance, average variance, and standard deviation
export function calculateStatistics(arr: number[]) {
    if (arr.length === 0) {
        return {
            variance: 0,
            avgVariance: 0,
            standardDeviation: 0,
        }
    }

    // Calculate the mean (average)
    const mean = arr.reduce((sum, num) => sum + num, 0) / arr.length;

    // Calculate the sum of squared differences for variance
    const sumSquaredDiff = arr.reduce((sum, num) => sum + Math.pow(num - mean, 2), 0);

    // Calculate the variance
    const variance = sumSquaredDiff / (arr.length - 1);

    // Calculate the average variance
    const avgVariance = variance / arr.length;

    // Calculate the standard deviation (square root of the variance)
    const standardDeviation = Math.sqrt(variance);

    return {
        variance: variance,
        avgVariance: avgVariance,
        standardDeviation: standardDeviation,
    };
}


// Parse a number from a string, or return 0 if it can't be parsed
export const parseNumber = (num: UnparsedNumberType): number => {
    try {
        if (typeof num === 'number') {
            return num;
        }

        if (typeof num === 'string') {
            // Remove commas, dollarsigns, and spaces
            num = num.replace(/,|\$| /g, '');
            const parsed = parseFloat(num);
            if (!isNaN(parsed)) {
                return parsed;
            }
        }
        return 0;
    } catch (err) {
        return 0;
    }
}

// Safely add numbers
export const addNumbers = (...nums: UnparsedNumberType[]) => {
    return (nums || []).reduce((sum, num) => (sum || 0) + parseNumber(num), 0);
}

// Safely subtract numbers
export const subtract = (...nums: UnparsedNumberType[]) => {
    return (nums || []).reduce((sum, num) => (sum || 0) - parseNumber(num), 0);
}

