import { randomNumber, utils } from '.';
import states from '../assets/states';
import { MailingAddress } from '@/legacy-types';

export function generateRandomWeedStoreName() {
	const adjectives = [
		'Green',
		'Bud',
		'High',
		'Blissful',
		'Cannabis',
		'Dank',
		'Herbal',
		'Sticky',
		'Puff',
		'Blazed',
		'Sativa',
		'Indica',
		'Mellow',
		'Elevated',
		'Lush',
		'Smoky',
		'Aromatic',
		'Frosty',
		'Chill',
		'Zen',
		'Peaceful',
		'Giggle',
		'Mystic',
		'Tropical',
		'Soothing',
		'Euphoric',
		'Tranquil',
		'Golden',
		'Vivid',
		'Dreamy',
		'Velvet',
		'Enlightened',
		'Spicy',
		'Sensual',
		'Divine',
		'Majestic',
		'Harmonious',
		'Sacred',
		'Astral',
		'Mystical',
		'Velvet',
		'Cosmic',
		'Starlight',
		'Luminous',
		'Whimsical',
		'Ethereal',
		'Radiant',
	];
	const nouns = [
		'Leaf',
		'Joint',
		'Dispensary',
		'420',
		'Ganja',
		'Bud',
		'Herb',
		'Stash',
		'Medicine',
		'Dope',
		'Bliss',
		'Harvest',
		'Nugget',
		'Magic',
		'Heaven',
		'Tonic',
		'Wellness',
		'Relief',
		'Serenity',
		'Dream',
		'Potion',
		'Palm',
		'Sorcery',
		'Serenade',
		'Infinity',
		'Breeze',
		'Vortex',
		'Secret',
		'Nectar',
		'Crystal',
		'Nirvana',
		'Fusion',
		'Shangri-La',
		'Elixir',
		'Alchemy',
		'Voyage',
		'Paradox',
		'Whisper',
		'Panacea',
		'Symphony',
		'Echo',
		'Spectrum',
		'Rapture',
		'Pulse',
		'Abyss',
		'Utopia',
	];

	const randomAdjectiveIndex = Math.floor(Math.random() * adjectives.length);
	const randomNounIndex = Math.floor(Math.random() * nouns.length);
	const randomAdjective = adjectives[randomAdjectiveIndex];
	const randomNoun = nouns[randomNounIndex];

	return `${randomAdjective} ${randomNoun} Shop`;
}
export function generateRandomStoreLocationName() {
	const adjectives = [
		'Happy',
		'Sunny',
		'Green',
		'Cozy',
		'Delicious',
		'Bright',
		'Sparkling',
		'Charming',
		'Friendly',
		'Magical',
		'Enchanted',
		'Tranquil',
		'Quaint',
		'Radiant',
		'Whimsical',
		'Idyllic',
		'Picturesque',
		'Serene',
		'Candlelit',
		'Enchanted',
		'Lively',
		'Scenic',
		'Peaceful',
		'Vibrant',
		'Gentle',
		'Rustic',
		'Serendipity',
		'Bucolic',
		'Dreamy',
		'Quixotic',
		'Placid',
		'Pastoral',
		'Elysian',
		'Halcyon',
		'Cherished',
		'Heavenly',
		'Sylvan',
		'Cosy',
		'Solemn',
		'Seraphic',
		'Unspoiled',
		'Mirthful',
		'Alluring',
		'Harmonious',
		'Enraptured',
		'Joyful',
		'Ineffable',
	];
	const nouns = [
		'Garden',
		'Cottage',
		'Haven',
		'Corner',
		'Oasis',
		'Paradise',
		'Nook',
		'Retreat',
		'Hearth',
		'Sanctuary',
		'Emporium',
		'Market',
		'Boutique',
		'Den',
		'Shack',
		'Shire',
		'Hollow',
		'Meadow',
		'Hillside',
		'Village',
		'Villa',
		'Hacienda',
		'Domain',
		'Grove',
		'Lodge',
		'Hinterland',
		'Perch',
		'Mystique',
		'Eden',
		'Arcadia',
		'Pinnacle',
		'Edifice',
		'Sanctum',
		'Rendezvous',
		'Respite',
		'Asylum',
		'Elm',
		'Bower',
		'Crescent',
		'Zenith',
		'Temple',
		'Chateau',
		'Henge',
		'Vortex',
		'Voyage',
		'Sculpture',
	];

	const randomAdjectiveIndex = Math.floor(Math.random() * adjectives.length);
	const randomNounIndex = Math.floor(Math.random() * nouns.length);
	const randomAdjective = adjectives[randomAdjectiveIndex];
	const randomNoun = nouns[randomNounIndex];

	return `${randomAdjective} ${randomNoun}`;
}
export function generateRandomSnonymForPersona() {
	const randomIndex = Math.floor(Math.random() * synonyms.length);
	return synonyms[randomIndex];
}
export function generateRandomTimezone() {
	const randomIndex = Math.floor(Math.random() * timezones.length);
	return timezones[randomIndex];
}
export function generateRandomProductName() {
	const randomIndex = Math.floor(Math.random() * commonProductNames.length);
	return commonProductNames[randomIndex];
}
export function generateRandomCategory() {
	const randomIndex = Math.floor(Math.random() * commonCategories.length);
	return commonCategories[randomIndex];
}
export function generateRandomWeedCategory() {
	const randomIndex = Math.floor(Math.random() * weedCategories.length);
	return weedCategories[randomIndex];
}
export function generateRandomWeedBrand() {
	const randomIndex = Math.floor(Math.random() * weedBrands.length);
	return weedBrands[randomIndex];
}
export function generateRandomWeedProduct() {
	const randomIndex = Math.floor(Math.random() * weedProducts.length);
	return weedProducts[randomIndex];
}
export function generateRandomBrand() {
	const randomIndex = Math.floor(Math.random() * commonBrands.length);
	return commonBrands[randomIndex];
}
export function generateRandomCity() {
	const randomIndex = Math.floor(Math.random() * cities.length);
	return cities[randomIndex];
}
export function generateRandomState() {
	const randomIndex = Math.floor(Math.random() * Object.keys(states).length);
	return Object.keys(states)[randomIndex];
}
export function generateRandomStateAbbreviation() {
	const randomIndex = Math.floor(Math.random() * Object.keys(states).length);
	return states[Object.keys(states)[randomIndex]];
}
export function generateRandomCountry() {
	const randomIndex = Math.floor(Math.random() * countries.length);
	return countries[randomIndex];
}
export function generateRandomZipCode() {
	const min = 10000; // Minimum zip code value
	const max = 99999; // Maximum zip code value

	const randomZip = Math.floor(Math.random() * (max - min + 1) + min);
	return randomZip.toString().padStart(5, '0'); // Convert to string and pad with leading zeros if necessary
}

export function generateRandomString(length = 8) {
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let randomString = '';

	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		randomString += characters.charAt(randomIndex);
	}

	return randomString;
}
export function generateRandomId() {
	const min = 10000; // Minimum value (inclusive)
	const max = 999999; // Maximum value (inclusive)

	const randomId = Math.floor(Math.random() * (max - min + 1)) + min;
	return randomId.toString();
}

export function generateRandomContactId() {
	const characters = 'abcdef0123456789';
	const sections = [8, 4, 4, 4, 12];

	let contactId = '';
	sections.forEach((sectionLength, index) => {
		for (let i = 0; i < sectionLength; i++) {
			const randomIndex = Math.floor(Math.random() * characters.length);
			contactId += characters.charAt(randomIndex);
		}
		if (index < sections.length - 1) {
			contactId += '-';
		}
	});

	return contactId;
}
export function generateRandomDays() {
	const randDays = [0, 7, 14, 30, 60, 90, 180, 365];
	const randDayOneIndex = Math.floor(Math.random() * (randDays.length - 1));
	const randDaysTwoIndex = Math.floor(Math.random() * (randDays.length - randDayOneIndex - 1)) + randDayOneIndex + 1;
	const toRolling = randDays[randDayOneIndex];
	const fromRolling = randDays[randDaysTwoIndex];
	return [toRolling, fromRolling];
}
export function generateRandomGender() {
	const genders = ['Male', 'Female', 'Other'];
	const randomIndex = Math.floor(Math.random() * genders.length);
	return genders[randomIndex];
}
export function generateRandomUrl() {
	const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
	const length = 10; // Change the desired length of the random URL

	let randomUrl = '';
	for (let i = 0; i < length; i++) {
		randomUrl += characters.charAt(Math.floor(Math.random() * characters.length));
	}

	return `https://example.com/${randomUrl}`;
}
export function generateRandomDate() {
	const startDate = new Date(1900, 0, 1);
	const endDate = new Date();
	const randomTimestamp = startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime());
	return new Date(randomTimestamp);
}

// Generate random phone number
export function generateRandomPhoneNumber() {
	const min = 1000000000; // Minimum value (inclusive)
	const max = 9999999999; // Maximum value (inclusive)

	const randomPhoneNumber = Math.floor(Math.random() * (max - min + 1)) + min;
	return randomPhoneNumber.toString();
}

// Generate random email with realistic looking domain
export function generateRandomEmail() {
	const randomString = generateRandomString();
	const randomDomain = generateRandomDomain();
	return `${randomString}@${randomDomain}`;
}

// Generate random domain
export function generateRandomDomain() {
	const domains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com', 'aol.com', 'icloud.com', 'protonmail.com', 'zoho.com', 'gmx.com', 'mail.com'];
	const randomIndex = Math.floor(Math.random() * domains.length);
	return domains[randomIndex];
}

function generateStreetAddress(): string {
	const streetPrefixes = ['North', 'South', 'East', 'West', 'Old', 'New', 'First', 'Second', 'Third', 'Fourth'];

	const streetSuffixes = ['Street', 'Avenue', 'Lane', 'Road', 'Drive', 'Boulevard', 'Circle', 'Court', 'Place', 'Terrace'];

	const streetNames = [
		'Main',
		'Elm',
		'Maple',
		'Oak',
		'Cedar',
		'Pine',
		'Birch',
		'Willow',
		'Hickory',
		'Cypress',
		'Spruce',
		'Sycamore',
		'Juniper',
		'Alder',
		'Magnolia',
		'Poplar',
		'Chestnut',
		'Redwood',
		'Walnut',
		'Cherry',
		'Locust',
		'Beech',
		'Ash',
		'Sassafras',
		'Dogwood',
		'Fir',
		'Hemlock',
		'Acacia',
		'Bamboo',
		'Cactus',
		'Eucalyptus',
		'Ginkgo',
		'Jasmine',
		'Lilac',
		'Mimosa',
		'Nectarine',
		'Orange',
		'Palm',
		'Quince',
		'Raspberry',
		'Sage',
		'Tangerine',
		'Umbrella',
		'Vine',
		'Willow',
		'Xylophone',
		'Yucca',
		'Zinnia',
	];

	const randomPrefixIndex = Math.floor(Math.random() * streetPrefixes.length);
	const randomSuffixIndex = Math.floor(Math.random() * streetSuffixes.length);
	const randomNameIndex = Math.floor(Math.random() * streetNames.length);

	const prefix = streetPrefixes[randomPrefixIndex];
	const suffix = streetSuffixes[randomSuffixIndex];
	const name = streetNames[randomNameIndex];

	return `${randomNumber(1000, 99999)} ${prefix} ${name} ${suffix}`;
}

export const generateMailingAddress = (): MailingAddress => {
	return {
		street: generateStreetAddress(),
		city: generateRandomCity(),
		state: generateRandomState(),
		zipCode: generateRandomZipCode(),
		country: generateRandomCountry(),
	};
};

// @ts-ignore
window['generateRandomStoreName'] = generateRandomStoreLocationName;
// @ts-ignore
window['generateMailingAddress'] = generateMailingAddress;

function formatDate(date = undefined as Date | undefined, randomize = false) {
	if (date === undefined) date = generateRandomDate();
	const options = { month: '2-digit', day: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false } as any;

	if (randomize) {
		const randomMinutes = Math.floor(Math.random() * 60);
		const randomHours = Math.floor(Math.random() * 24);

		date.setMinutes(randomMinutes);
		date.setHours(randomHours);

		const randomFormat = Math.random() < 0.5 ? 'MM-DD-YY' : 'default';
		if (randomFormat === 'MM-DD-YY') {
			options.month = '2-digit';
			options.day = '2-digit';
			options.year = '2-digit';
		}
	}

	const formattedDate = date.toLocaleString('en-US', options).replace(',', ' at');
	return formattedDate;
}
export function generateRandomDates(minDaysApart = 1, maxDaysApart = 30, format = true) {
	const minDate = new Date();
	minDate.setDate(minDate.getDate() - maxDaysApart);

	const maxDate = new Date();
	maxDate.setDate(maxDate.getDate() - minDaysApart);

	const randomTimestamp1 = Math.random() * (maxDate.getTime() - minDate.getTime()) + minDate.getTime();
	const randomTimestamp2 = Math.random() * (maxDate.getTime() - minDate.getTime()) + minDate.getTime();

	const randomDate1 = new Date(randomTimestamp1);
	const randomDate2 = new Date(randomTimestamp2);

	if (format) {
		return [formatDate(randomDate1), formatDate(randomDate2)];
	}

	return [randomDate1, randomDate2];
}
export function generateRandomNumberRange(min = 0, max = 100) {
	if (min > max) {
		[min, max] = [max, min];
	}

	const randomNumber1 = Math.floor(Math.random() * (max - min + 1)) + min;
	const randomNumber2 = Math.floor(Math.random() * (max - min + 1)) + min;

	return [randomNumber1, randomNumber2];
}
export function generateRandomCommonName(both = true, prev?: string): string {
	const combinedNames = [...firstNames, ...lastNames];

	const randomIndex = Math.floor(Math.random() * combinedNames.length);
	let randomName = combinedNames[randomIndex];

	// make sure we don't get the same name twice in a row
	while (randomName === prev || utils.strify(prev)?.includes(utils.strify(randomName))) {
		randomName = generateRandomCommonName(false, prev);
	}
	if (both) return `${randomName} ${generateRandomCommonName(false, randomName)}`;

	return randomName;
}
export function generateRandomWord(capitalize = false) {
	const randomIndex = Math.floor(Math.random() * wordList.length);
	const word = wordList[randomIndex];
	return capitalize ? word.charAt(0).toUpperCase() + word.slice(1) : word;
}

export function generateRandomName() {
	const nameLength = Math.floor(Math.random() * 2) + 2;
	let name = '';

	for (let i = 0; i < nameLength; i++) {
		const randomSyllableIndex = Math.floor(Math.random() * syllables.length);
		name += syllables[randomSyllableIndex];
	}

	return name.charAt(0).toUpperCase() + name.slice(1);
}

// Generate random paragraph of text
export function generateRandomParagraph(minLength = 3, maxLength = 10) {
	const paragraphLength = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
	let paragraph = '';

	for (let i = 0; i < paragraphLength; i++) {
		const randomWordIndex = Math.floor(Math.random() * wordList.length);
		paragraph += `${wordList[randomWordIndex]} `;
	}

	return paragraph;
}

const syllables = [
	'ba',
	'be',
	'bi',
	'bo',
	'bu',
	'da',
	'de',
	'di',
	'do',
	'du',
	'fa',
	'fe',
	'fi',
	'fo',
	'fu',
	'ga',
	'ge',
	'gi',
	'go',
	'gu',
	'ha',
	'he',
	'hi',
	'ho',
	'hu',
	'ja',
	'je',
	'ji',
	'jo',
	'ju',
	'ka',
	'ke',
	'ki',
	'ko',
	'ku',
	'la',
	'le',
	'li',
	'lo',
	'lu',
	'ma',
	'me',
	'mi',
	'mo',
	'mu',
	'na',
	'ne',
	'ni',
	'no',
	'nu',
	'pa',
	'pe',
	'pi',
	'po',
	'pu',
	'ra',
	're',
	'ri',
	'ro',
	'ru',
	'sa',
	'se',
	'si',
	'so',
	'su',
	'ta',
	'te',
	'ti',
	'to',
	'tu',
	'va',
	've',
	'vi',
	'vo',
	'vu',
	'wa',
	'we',
	'wi',
	'wo',
	'wu',
	'ya',
	'ye',
	'yi',
	'yo',
	'yu',
	'za',
	'ze',
	'zi',
	'zo',
	'zu',
];

const commonBrands = [
	'Nike',
	'Apple',
	'Samsung',
	'Microsoft',
	'Amazon',
	'Google',
	'Coca-Cola',
	'Toyota',
	"McDonald's",
	'Adidas',
	'Sony',
	'Honda',
	'Facebook',
	'Intel',
	'Netflix',
	'Walmart',
	'Volkswagen',
	'Mercedes-Benz',
	'BMW',
	'Pepsi',
	'Target',
	'Ford',
	'Lego',
	'General Electric',
	'Uber',
	'Airbnb',
	'Twitter',
	'Canon',
	'Spotify',
	'Visa',
	// Add more common brands...
];

const weedBrands = [
	'Columbia Care',
	'Cookies',
	'Wana',
	'Green Thumb Industries',
	'Curaleaf',
	'Acreage Holdings',
	'Grassroots',
	'Cresco Labs',
	'Trulieve',
	'GTI',
	'Harvest Health & Recreation',
	'Vireo Health',
	'MedMen',
	'TerrAscend',
	'Jushi Holdings',
	'Calyx Peak',
	"Charlotte's Web",
	'Verano Holdings',
	'Harborside',
	'Holistic Industries',
	'4Front Ventures',
	'Harmony Extracts',
	'High Times',
	'KushCo Holdings',
	'Terra Tech',
	'PharmaCann',
	'The Botanist',
	'Revolution Global',
	'RYAH Medtech',
	'Apothecarium',
	'Humboldt Farms',
	"Willie's Reserve",
	'Kiva Confections',
	'Kurvana',
	'PAX Labs',
	'Raw Garden',
	'STIIIZY',
	'WYLD',
	'Vangst',
	'Marley Natural',
	'Viola',
	'Dr. Dabber',
	'Lagunitas Hi-Fi Hops',
	'Dosist',
	'Moxie',
	'Canndescent',
	'Old Pal',
	'Jetty Extracts',
	'Lowell Farms',
	'Eaze',
	// Add more actual weed brands...
];

export const allBrands = [...commonBrands, ...weedBrands];

const countries = [
	'United States',
	'United Kingdom',
	'France',
	'Japan',
	'Australia',
	'Germany',
	'Italy',
	'Russia',
	'Canada',
	'United Arab Emirates',
	'China',
	'India',
	'Brazil',
	'South Africa',
	'Mexico',
	'Argentina',
	'Egypt',
	'Kenya',
	'Thailand',
	'Switzerland',
	// Add more countries...
];
const wordList = [
	'apple',
	'banana',
	'orange',
	'grape',
	'watermelon',
	'kiwi',
	'pineapple',
	'pear',
	'strawberry',
	'mango',
	'blueberry',
	'peach',
	'lemon',
	'lime',
	'coconut',
	'cherry',
	'pomegranate',
	'apricot',
	'blackberry',
	'plum',
	'raspberry',
	'melon',
	'fig',
	'nectarine',
	'guava',
	'cantaloupe',
	'avocado',
	'grapefruit',
	'papaya',
	'passionfruit',
	'dragonfruit',
	'persimmon',
	'quince',
	'lychee',
	'mulberry',
	'honeydew',
	'cranberry',
	'tangerine',
	'kiwifruit',
	'boysenberry',
	'date',
	'elderberry',
	'gooseberry',
	'starfruit',
	'marionberry',
	'plantain',
	'breadfruit',
	'rhubarb',
	'pine',
	'almond',
	'cashew',
	'walnut',
	'pecan',
	'hazelnut',
	'chestnut',
	'macadamia',
	'peanut',
	'pistachio',
	'celery',
	'carrot',
	'broccoli',
	'cauliflower',
	'cabbage',
	'asparagus',
	'spinach',
	'kale',
	'lettuce',
	'radish',
	'onion',
	'garlic',
	'ginger',
	'beetroot',
	'turnip',
	'parsnip',
	'potato',
	'sweet potato',
	'yam',
	'mushroom',
	'tomato',
	'bell pepper',
	'cucumber',
	'zucchini',
	'eggplant',
	'corn',
	'peas',
	'beans',
	'lentil',
	'chickpea',
	'quinoa',
	'rice',
	'barley',
	'oats',
	'wheat',
	'rye',
	'spelt',
	'millet',
	'bulgur',
	'buckwheat',
	'abacus',
	'anchor',
	'butterfly',
	'carousel',
	'dandelion',
	'elephant',
	'fireworks',
	'giraffe',
	'hurricane',
	'iceberg',
	'jigsaw',
	'kangaroo',
	'lighthouse',
	'magnolia',
	'narwhal',
	'octopus',
	'parachute',
	'quicksand',
	'rainbow',
	'sunflower',
	'telescope',
	'umbrella',
	'volcano',
	'waterfall',
	'xylophone',
	'yacht',
	'zeppelin',
	'avalanche',
	'breeze',
	'cactus',
	'desert',
	'echo',
	'fossil',
	'glacier',
	'hailstorm',
	'island',
	'jungle',
	'kayak',
	'lagoon',
	'meadow',
	'nightingale',
	'oasis',
	'palm',
	'quail',
	'rattlesnake',
	'sandcastle',
	'thunder',
	'universe',
	'volleyball',
	'whirlpool',
	'x-ray',
	'yoga',
	'zodiac',
	'astronomy',
	'ballet',
	'chemistry',
	'drama',
	'economics',
	'geography',
	'history',
	'journalism',
	'kinesiology',
	'linguistics',
	'mathematics',
	'nursing',
	'oceanography',
	'philosophy',
	'quantum',
	'radiology',
	'sociology',
	'theology',
	'uranium',
	'virology',
	'wildlife',
	'xenophobia',
	'youth',
	'zoology',
	'altruism',
	'benevolence',
	'courage',
	'diligence',
	'empathy',
	'fortitude',
	'gratitude',
	'humility',
	'integrity',
	'justice',
	'kindness',
	'loyalty',
	'modesty',
	'nobility',
	'optimism',
	'patience',
	'quintessence',
	'resilience',
	'serenity',
	'tranquility',
	'unity',
	'virtue',
	'wisdom',
	'xenial',
	'youthful',
	'zealous',
	'amazing',
	'brilliant',
	'courageous',
	'dazzling',
	'energetic',
	'fearless',
	'graceful',
	'humble',
	'intelligent',
	'jovial',
	'keen',
	'lively',
	'magnificent',
	'noble',
	'optimistic',
	'passionate',
	'quiet',
	'radiant',
	'sincere',
	'triumphant',
	'uplifting',
	'vibrant',
	'witty',
	'xenial',
	'youthful',
	'zealous',
	'aardvark',
	'blossom',
	'cascade',
	'dolphin',
	'evergreen',
	'firefly',
	'galaxy',
	'harmony',
	'iguana',
	'jubilee',
	'koala',
	'lullaby',
	'moonlight',
	'nectar',
	'opulent',
	'penguin',
	'quasar',
	'raindrop',
	'serenade',
	'tiger',
	'unicorn',
	'volley',
	'waterfront',
	'xylograph',
	'yesteryear',
	'zestful',
	'abundance',
	'benevolent',
	'conquer',
	'diligent',
	'ecstasy',
	'fascinate',
	'gentle',
	'humble',
	'illuminate',
	'jubilant',
	'kindle',
	'luminous',
	'mystify',
	'nurture',
	'optimism',
	'paradise',
	'quiescent',
	'resilient',
	'serene',
	'triumph',
	'utopia',
	'valiant',
	'whimsical',
	'xyloid',
	'yearn',
	'zealot',
	'amiable',
	'brilliance',
	'captivate',
	'dexterity',
	'eloquence',
	'fervent',
	'glisten',
	'harmonious',
	'innocent',
	'jubilation',
	'kindness',
	'luxuriant',
	'mirth',
	'nourish',
	'optimistic',
	'profound',
	'quintessential',
	'ravishing',
	'serenity',
	'tantalize',
	'unite',
	'vivid',
	'wondrous',
	'xenial',
	'youthful',
	'zealot',
	'acorn',
	'breeze',
	'crystal',
	'dragon',
	'eleven',
	'feather',
	'goblin',
	'hedgehog',
	'isolate',
	'jungle',
	'kitten',
	'lemon',
	'marble',
	'noodle',
	'ocean',
	'panda',
	'quilt',
	'rain',
	'sailor',
	'thunder',
	'unicorn',
	'violet',
	'wander',
	'xylophone',
	'yoga',
	'zebra',
	'apple',
	'banana',
	'cherry',
	'grape',
	'lemon',
	'mango',
	'orange',
	'papaya',
	'peach',
	'pear',
	'pineapple',
	'raspberry',
	'strawberry',
	'watermelon',
];

const firstNames = [
	'James',
	'John',
	'Robert',
	'Michael',
	'William',
	'David',
	'Joseph',
	'Charles',
	'Thomas',
	'Daniel',
	'Matthew',
	'Anthony',
	'Donald',
	'Mark',
	'Paul',
	'Steven',
	'Andrew',
	'Kenneth',
	'George',
	'Joshua',
	'Emily',
	'Emma',
	'Olivia',
	'Ava',
	'Isabella',
	'Sophia',
	'Mia',
	'Charlotte',
	'Amelia',
	'Harper',
	'Mary',
	'Jennifer',
	'Linda',
	'Patricia',
	'Susan',
	'Karen',
	'Nancy',
	'Lisa',
	'Margaret',
	'Betty',
	'Richard',
	'Edward',
	'Henry',
	'Frank',
	'Jack',
	'Albert',
	'Walter',
	'Harold',
	'Raymond',
	'Harry',
	'Dorothy',
	'Elizabeth',
	'Maria',
	'Susan',
	'Donna',
	'Deborah',
	'Jessica',
	'Sharon',
	'Michelle',
	'Cynthia',
	'Sarah',
	'Rebecca',
	'Laura',
	'Helen',
	'Natalie',
	'Grace',
	'Madison',
	'Alyssa',
	'Sophie',
	'Zoe',
	'Ethan',
	'Sophie',
	'Liam',
	'Lucas',
	'Grace',
	'Ella',
	'Aiden',
	'Noah',
	'Harper',
	'Chloe',
	'Aiden',
	'Benjamin',
	'Charlotte',
	'David',
	'Edward',
	'Evelyn',
	'Zoe',
	'Gabriel',
	'Hannah',
	'Isaac',
	'Julia',
	'Kennedy',
	'Lillian',
	'Madeline',
	'Natalie',
	'Oliver',
	'Parker',
	'Quinn',
	'Riley',
	'Samuel',
	'Tristan',
	'Ulysses',
	'Victoria',
	'Wesley',
	'Xander',
	'Yasmine',
	'Zachary',
	'Alison',
	'Brianna',
	'Caleb',
	'Daisy',
	'Eli',
	'Fiona',
	'George',
	'Hazel',
	'Ian',
	'Juliette',
	'Kaden',
	'Layla',
	'Mason',
	'Nora',
	'Oscar',
	'Penelope',
	'Quincy',
	'Rebecca',
	'Samantha',
	'Theodore',
	'Uma',
	'Violet',
	'Wyatt',
	'Xavier',
	'Yara',
	'Zara',
	'Aaron',
	'Bianca',
	'Cameron',
	'Delilah',
	'Ezra',
	'Felix',
	'Gemma',
	'Harrison',
	'Ivy',
	'Jack',
	'Katherine',
	'Luna',
	'Milo',
	'Nina',
	'Owen',
	'Poppy',
	'Quinn',
	'Rowan',
	'Stella',
	'Thea',
	'Uriah',
	'Vivian',
	'Weston',
	'Xena',
	'Yasmin',
	'Zane',
	// Add more common first names...
];

const lastNames = [
	'Smith',
	'Johnson',
	'Williams',
	'Jones',
	'Brown',
	'Davis',
	'Miller',
	'Wilson',
	'Moore',
	'Taylor',
	'Anderson',
	'Thomas',
	'Jackson',
	'White',
	'Harris',
	'Martin',
	'Thompson',
	'Garcia',
	'Martinez',
	'Robinson',
	'Lee',
	'Walker',
	'Hall',
	'Allen',
	'Young',
	'King',
	'Wright',
	'Scott',
	'Green',
	'Baker',
	'Adams',
	'Clark',
	'Lewis',
	'Hill',
	'Carter',
	'Mitchell',
	'Perez',
	'Roberts',
	'Turner',
	'Phillips',
	'Campbell',
	'Parker',
	'Evans',
	'Edwards',
	'Collins',
	'Stewart',
	'Morris',
	'Nguyen',
	'Murphy',
	'Watson',
	'Gonzalez',
	'Rodriguez',
	'Lopez',
	'Hernandez',
	'Diaz',
	'Martin',
	'Wood',
	'Allen',
	'Hall',
	'Rivera',
	'Long',
	'Reyes',
	'Foster',
	'Sanders',
	'Butler',
	'Perry',
	'Price',
	'Barnes',
	'Ross',
	'Adams',
	'Bennett',
	'Clark',
	'Diaz',
	'Evans',
	'Foster',
	'Gomez',
	'Harrison',
	'Irwin',
	'Johnson',
	'Keller',
	'Lopez',
	'Morgan',
	'Nguyen',
	'Ortega',
	'Perez',
	'Quinn',
	'Reyes',
	'Smith',
	'Turner',
	'Underwood',
	'Vargas',
	'Watson',
	'Xiong',
	'Yates',
	'Zimmerman',
	'Avery',
	'Blake',
	'Cole',
	'Dawson',
	'Ellis',
	'Ferguson',
	'Gordon',
	'Hayes',
	'Ingram',
	'Jensen',
	'Knox',
	'Lawrence',
	'McCarthy',
	'Newton',
	'Owens',
	'Porter',
	'Quinn',
	'Reid',
	'Stewart',
	'Torres',
	'Vaughn',
	'Wheeler',
	'Xu',
	'Zhang',
	// Add more common last names...
];

const weedProducts = [
	'Sour Diesel',
	'Blue Dream',
	'OG Kush',
	'Girl Scout Cookies',
	'White Widow',
	'Northern Lights',
	'Jack Herer',
	'Durban Poison',
	'Pineapple Express',
	'Gorilla Glue',
	'AK-47',
	'Purple Haze',
	'Wedding Cake',
	'Gelato',
	'Bruce Banner',
	'Cherry Pie',
	'Tangie',
	'Lemon Haze',
	'Strawberry Cough',
	'Granddaddy Purple',
	'Sour OG',
	'Amnesia Haze',
	'GSC (formerly Girl Scout Cookies)',
	'Gelato #33',
	'Forbidden Fruit',
	'Zkittlez',
	'Lemon Kush',
	'Tahoe OG',
	'Chemdawg',
	'Cookies and Cream',
	'Northern Lights #5',
	'Super Lemon Haze',
	'Pineapple Kush',
	'Black Widow',
	'Cherry AK-47',
	'Purple Punch',
	'Ghost OG',
	'Bruce Banner #3',
	'L.A. Confidential',
	'Candyland',
	'Chocolope',
	'Mimosa',
	'Sunset Sherbet',
	'Dosidos',
	'Platinum OG',
	'WiFi OG',
];

const commonCategories = [
	'Electronics',
	'Clothing',
	'Home',
	'Beauty',
	'Books',
	'Sports',
	'Automotive',
	'Health',
	'Toys',
	'Office',
	'Pets',
	'Grocery',
	'Tools',
	'Baby',
	'Music',
	'Movies',
	'Industrial',
	'Furniture',
	'Jewelry',
	'Fitness',
	'Art',
	'Shoes',
	'Appliances',
	'Party',
	'Software',
	'Camera',
	'Phones',
	'Collectibles',
	'Garden',
	'Instruments',
	'Watches',
	'Luggage',
	'Food',
	'Hobbies',
	'Decor',
	'Instruments',
	'Kitchen',
	'Supplies',
	'Outdoor',
	'Audio',
	'Care',
	'Accessories',
	'School',
];

const weedCategories = [
	'Flower',
	'Edibles',
	'Concentrates',
	'Pre-Rolls',
	'Vapes',
	'Topicals',
	'Tinctures',
	'Accessories',
	'CBD Products',
	'Seeds',
	'Grow Equipment',
	'Dab Rigs',
	'Pipes',
	'Grinders',
	'Bongs',
	'Rolling Papers',
	'Storage Containers',
	'Lighters',
];

export const allCategories = [...commonCategories, ...weedCategories];

const commonProductNames = [
	'Laptop',
	'T-Shirt',
	'Coffee Mug',
	'Headphones',
	'Sneakers',
	'Smartphone',
	'Camera',
	'Backpack',
	'Watch',
	'Gaming Console',
	'Book',
	'Dumbbell Set',
	'Blender',
	'Running Shoes',
	'Bluetooth Speaker',
	'Sunglasses',
	'Wristwatch',
	'Digital Camera',
	'Pencil Set',
	'Yoga Mat',
	'Guitar',
	'Vacuum Cleaner',
	'Hiking Boots',
	'Wireless Earbuds',
	'Water Bottle',
	'E-book Reader',
	'Hair Dryer',
	'Toothbrush',
	'Cookware Set',
	'Fitness Tracker',
	'Desktop Computer',
	'Portable Charger',
	'Hoodie',
	'Printer',
	'Folding Chair',
	'Crossbody Bag',
	'Dress Shoes',
	'External Hard Drive',
	'Kitchen Knife Set',
	'Camping Tent',
	'Office Chair',
	'Running Shorts',
	'Smartwatch',
	'Air Purifier',
	'Projector',
	'Travel Pillow',
	'Blu-ray Player',
	'Power Drill',
	'Back Massager',
	'Yoga Block',
	'Wireless Mouse',
	'Sleeping Bag',
	'Protein Powder',
	'Coffee Maker',
	'Tablet',
	'Artificial Plant',
	'Tennis Shoes',
	'Wireless Keyboard',
	'Instant Pot',
	'Alarm Clock',
	'Gaming Mouse',
	'Fitness Band',
	'Resistance Bands',
	'Gaming Headset',
	'Portable Speaker',
	'Wall Clock',
	'Travel Backpack',
	'LED Light Bulbs',
	'Pedometer',
	'Electric Toothbrush',
	'Cutting Board',
	'Jump Rope',
	'Resistance Bands',
	'Flashlight',
];

export const allProducts = [...commonProductNames, ...weedProducts];

const cities = [
	'New York',
	'Los Angeles',
	'Chicago',
	'Houston',
	'Phoenix',
	'Philadelphia',
	'San Antonio',
	'San Diego',
	'Dallas',
	'San Jose',
	'Austin',
	'Jacksonville',
	'San Francisco',
	'Indianapolis',
	'Columbus',
	'Fort Worth',
	'Charlotte',
	'Seattle',
	'Denver',
	'Washington',
	'Boston',
	'El Paso',
	'Detroit',
	'Nashville',
	'Portland',
	'Memphis',
	'Oklahoma City',
	'Las Vegas',
	'Louisville',
	'Baltimore',
	'Milwaukee',
	'Albuquerque',
	'Tucson',
	'Fresno',
	'Sacramento',
	'Mesa',
	'Kansas City',
	'Atlanta',
	'Long Beach',
	'Colorado Springs',
	'Raleigh',
	'Miami',
	'Virginia Beach',
	'Omaha',
	'Oakland',
	'Minneapolis',
	'Tulsa',
	'Arlington',
	'New Orleans',
	'Wichita',
	'Cleveland',
	'Tampa',
	'Bakersfield',
	'Aurora',
	'Anaheim',
	'Honolulu',
	'Santa Ana',
	'Riverside',
	'Corpus Christi',
	'Lexington',
	// Add more cities...
];

const synonyms = [
	// Synonyms for "individuals"
	'Contacts',
	'People',
	'Personas',
	'Members',
	'Shoppers',
	'Patrons',
	'Persons',
	'Individuals',
	'Folks',
	'Persons of interest',
	'Personalities',
	'Entities',
	'Individuals',
	'Subjects',
	'Viewers',
	'Prospects',
	'Promising leads',
	'Candidates',
	'Suitors',
	'Records',
	'Entries',
	'Accounts',
];

const timezones = [
	'Africa/Abidjan',
	'Africa/Accra',
	'Africa/Addis_Ababa',
	'Africa/Algiers',
	'Africa/Asmara',
	'Africa/Asmera',
	'Africa/Bamako',
	'Africa/Bangui',
	'Africa/Banjul',
	'Africa/Bissau',
	'Africa/Blantyre',
	'Africa/Brazzaville',
	'Africa/Bujumbura',
	'Africa/Cairo',
	'Africa/Casablanca',
	'Africa/Ceuta',
	'Africa/Conakry',
	'Africa/Dakar',
	'Africa/Dar_es_Salaam',
	'Africa/Djibouti',
	'Africa/Douala',
	'Africa/El_Aaiun',
	'Africa/Freetown',
	'Africa/Gaborone',
	'Africa/Harare',
	'Africa/Johannesburg',
	'Africa/Juba',
	'Africa/Kampala',
	'Africa/Khartoum',
	'Africa/Kigali',
	'Africa/Kinshasa',
	'Africa/Lagos',
	'Africa/Libreville',
	'Africa/Lome',
	'Africa/Luanda',
	'Africa/Lubumbashi',
	'Africa/Lusaka',
	'Africa/Malabo',
	'Africa/Maputo',
	'Africa/Maseru',
	'Africa/Mbabane',
	'Africa/Mogadishu',
	'Africa/Monrovia',
	'Africa/Nairobi',
	'Africa/Ndjamena',
	'Africa/Niamey',
	'Africa/Nouakchott',
	'Africa/Ouagadougou',
	'Africa/Porto-Novo',
	'Africa/Sao_Tome',
	'Africa/Timbuktu',
	'Africa/Tripoli',
	'Africa/Tunis',
	'Africa/Windhoek',
	'America/Adak',
	'America/Anchorage',
	'America/Anguilla',
	'America/Antigua',
	'America/Araguaina',
	'America/Argentina/Buenos_Aires',
	'America/Argentina/Catamarca',
	'America/Argentina/ComodRivadavia',
	'America/Argentina/Cordoba',
	'America/Argentina/Jujuy',
	'America/Argentina/La_Rioja',
	'America/Argentina/Mendoza',
	'America/Argentina/Rio_Gallegos',
	'America/Argentina/Salta',
	'America/Argentina/San_Juan',
	'America/Argentina/San_Luis',
	'America/Argentina/Tucuman',
	'America/Argentina/Ushuaia',
	'America/Aruba',
	'America/Asuncion',
	'America/Atikokan',
	'America/Atka',
	'America/Bahia',
	'America/Bahia_Banderas',
	'America/Barbados',
	'America/Belem',
	'America/Belize',
	'America/Blanc-Sablon',
	'America/Boa_Vista',
	'America/Bogota',
	'America/Boise',
	'America/Buenos_Aires',
	'America/Cambridge_Bay',
	'America/Campo_Grande',
	'America/Cancun',
	'America/Caracas',
	'America/Catamarca',
	'America/Cayenne',
	'America/Cayman',
	'America/Chicago',
	'America/Chihuahua',
	'America/Coral_Harbour',
	'America/Cordoba',
	'America/Costa_Rica',
	'America/Creston',
	'America/Cuiaba',
	'America/Curacao',
	'America/Danmarkshavn',
	'America/Dawson',
	'America/Dawson_Creek',
	'America/Denver',
	'America/Detroit',
	'America/Dominica',
	'America/Edmonton',
	'America/Eirunepe',
	'America/El_Salvador',
	'America/Ensenada',
	'America/Fort_Wayne',
	'America/Fortaleza',
	'America/Glace_Bay',
	'America/Godthab',
	'America/Goose_Bay',
	'America/Grand_Turk',
	'America/Grenada',
	'America/Guadeloupe',
	'America/Guatemala',
	'America/Guayaquil',
	'America/Guyana',
	'America/Halifax',
	'America/Havana',
	'America/Hermosillo',
	'America/Indiana/Indianapolis',
	'America/Indiana/Knox',
	'America/Indiana/Marengo',
	'America/Indiana/Petersburg',
	'America/Indiana/Tell_City',
	'America/Indiana/Vevay',
	'America/Indiana/Vincennes',
	'America/Indiana/Winamac',
	'America/Indianapolis',
	'America/Inuvik',
	'America/Iqaluit',
	'America/Jamaica',
	'America/Jujuy',
	'America/Juneau',
	'America/Kentucky/Louisville',
	'America/Kentucky/Monticello',
	'America/Knox_IN',
	'America/Kralendijk',
	'America/La_Paz',
	'America/Lima',
	'America/Los_Angeles',
	'America/Louisville',
	'America/Lower_Princes',
	'Pacific/Pago_Pago',
	'Pacific/Midway',
	'Pacific/Honolulu',
	'America/Juneau',
	'America/Los_Angeles',
	'America/Tijuana',
	'America/Denver',
	'America/Phoenix',
	'America/Chihuahua',
	'America/Mazatlan',
	'America/Chicago',
	'America/Regina',
	'America/Mexico_City',
	'America/Monterrey',
	'America/Guatemala',
	'America/New_York',
	'America/Indiana/Indianapolis',
	'America/Bogota',
	'America/Lima',
	'America/Halifax',
	'America/Caracas',
	'America/Guyana',
	'America/La_Paz',
	'America/Santiago',
	'America/St_Johns',
	'America/Sao_Paulo',
	'America/Argentina/Buenos_Aires',
	'America/Godthab',
	'Atlantic/South_Georgia',
	'Atlantic/Azores',
	'Atlantic/Cape_Verde',
	'Africa/Casablanca',
	'Europe/London',
	'Etc/GMT+1',
	'Europe/Berlin',
	'Europe/Paris',
	'Africa/Lagos',
	'Europe/Rome',
	'Europe/Athens',
	'Europe/Bucharest',
	'Africa/Cairo',
	'Europe/Helsinki',
	'Asia/Jerusalem',
	'Europe/Moscow',
	'Africa/Nairobi',
	'Asia/Baghdad',
	'Asia/Tehran',
	'Asia/Dubai',
	'Asia/Baku',
	'Asia/Kabul',
	'Asia/Karachi',
	'Asia/Kolkata',
	'Asia/Kathmandu',
	'Asia/Dhaka',
	'Asia/Colombo',
	'Asia/Almaty',
	'Asia/Rangoon',
	'Asia/Bangkok',
	'Asia/Krasnoyarsk',
	'Asia/Shanghai',
	'Asia/Kuala_Lumpur',
	'Asia/Taipei',
	'Australia/Perth',
	'Asia/Irkutsk',
	'Asia/Seoul',
	'Asia/Tokyo',
	'Asia/Yakutsk',
	'Australia/Darwin',
	'Australia/Adelaide',
	'Australia/Sydney',
	'Australia/Brisbane',
	'Australia/Hobart',
	'Asia/Vladivostok',
	'Pacific/Guam',
	'Pacific/Port_Moresby',
	'Asia/Magadan',
	'Asia/Kamchatka',
	'Pacific/Fiji',
	'Pacific/Auckland',
	'Pacific/Tongatapu',
];
