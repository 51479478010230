import { FullContact, MessageOut } from '@/types';
import { getFullName } from './format.tsx';
import utils from './utils';

export const MessageTypes = {
	isAd: 'Ad',
	snailMail: 'Direct mail',
	voice: 'Voice memo',
	isMMS: 'MMS',
	isSMS: 'SMS',
	isEmail: 'Email',
	isPush: 'Native push',
	isBrowser: 'Browser push',
} as const;

export type MessageTableType = keyof typeof MessageTypes;

export function getMessageTableType(message: MessageOut): MessageTableType {
	if (message.isAd) return 'isAd';
	if (message.isEmail) return 'isEmail';
	if (message.isPush) return 'isPush';
	if (message.isBrowser) return 'isBrowser';
	if (message.platform === 'telnyx-voice') return 'voice';
	if (message.platform === 'telnyx') return 'isSMS';
	if (message.isMMS) return 'isMMS';
	if (message.snailMail) return 'snailMail';
	return 'isSMS';
}

export function getFormattedMessageType(message: MessageOut): string {
	let type = 'SMS';
	if (message.isAd) type = 'Ad';
	if (message?.isEmail) type = 'Email';
	if (message.isPush) type = 'Native push';
	if (message.isBrowser) type = 'Browser push';
	if (message?.platform === 'telnyx-voice') type = 'Voice drop';
	if (message?.platform === 'telnyx') type = 'SMS';
	if (message?.isMMS) type = 'MMS';
	return type;
}

export function isMessageFromClient(message: MessageOut): boolean {
	return message.direction === 'inbound';
}

export function isMessageFailed(message: MessageOut): boolean {
	if (message.isEmail && message.errorMsg) {
		return true;
	}
	const isFailed = ['undelivered', 'failed', 'gw_timeout', 'dlr_timeout', 'sending_failed', 'delivery_failed'].includes(message.twilioStatus || '');
	return isFailed;
}

export function getFailedMessageReason(message: MessageOut): string {
	if (message.isEmail && message.errorMsg) {
		return message.errorMsg;
	}
	return message.twilioStatus || '';
}

export function getFormattedMessageDirection(message: MessageOut): string {
	if (isMessageFailed(message)) return 'failed';
	return isMessageFromClient(message) ? 'delivered' : 'received';
}

export function getFormattedMessageTo(message: MessageOut, fullContact?: FullContact): string {
	const fromClient = isMessageFromClient(message);
	if (!fromClient) {
		const profileName = utils.user.profile?.name;
		return profileName || message.from || 'You';
	}

	if (fullContact) {
		const fullName = getFullName(fullContact.firstName, fullContact.lastName);
		if (fullName) return fullName;
	}

	return message.to || 'Customer';
}

export function getFormattedMessageFrom(message: MessageOut, fullContact?: FullContact): string {
	const fromClient = isMessageFromClient(message);
	if (fromClient) {
		const profileName = utils.user.profile?.name;
		return profileName || message.from || 'You';
	}

	if (fullContact) {
		const fullName = getFullName(fullContact.firstName, fullContact.lastName);
		if (fullName) return fullName;
	}

	return message.from || 'Customer';
}
